import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timi-overview-container" }
const _hoisted_2 = { class: "timi-overview-content" }
const _hoisted_3 = { class: "date-slider" }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "main-content" }
const _hoisted_6 = { class: "headerinfo" }
const _hoisted_7 = {
  key: 0,
  class: "headline"
}
const _hoisted_8 = { class: "info" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "headeractions" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_ButtonGroup = _resolveComponent("ButtonGroup")!
  const _component_TimeEntryList = _resolveComponent("TimeEntryList")!
  const _component_ActiveWorkBar = _resolveComponent("ActiveWorkBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IconButton, {
          icon: _ctx.store.getters.svgIcons.arrowbackward,
          class: "",
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSwitchDay(-1)))
        }, null, 8, ["icon"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.displayActiveDay), 1),
        _createVNode(_component_IconButton, {
          icon: _ctx.store.getters.svgIcons.arrowforward,
          onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSwitchDay(1)))
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("header", null, [
          _createElementVNode("div", _hoisted_6, [
            false
              ? (_openBlock(), _createElementBlock("h2", _hoisted_7, [
                  _createTextVNode(_toDisplayString(_ctx.$t('work.works')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.displayActiveDay), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "svgicon svgicon--comego",
                  innerHTML: _ctx.icons.comego
                }, null, 8, _hoisted_9),
                _createTextVNode(_toDisplayString((_ctx.activePresence && _ctx.activePresence.come ? _ctx.convertTimeStamp(_ctx.activePresence.come) : '-:-')) + " / " + _toDisplayString((_ctx.activePresence && _ctx.activePresence.go ? _ctx.convertTimeStamp(_ctx.activePresence.go) : '-:-')), 1)
              ]),
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "svgicon svgicon--presence",
                  innerHTML: _ctx.icons.presence
                }, null, 8, _hoisted_10),
                _createTextVNode(" " + _toDisplayString(_ctx.convertSeconds(_ctx.presenceTime)), 1)
              ]),
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "svgicon svgicon--worktime",
                  innerHTML: _ctx.icons.worktime
                }, null, 8, _hoisted_11),
                _createTextVNode(" " + _toDisplayString(_ctx.convertSeconds(_ctx.daySum)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ButtonGroup, null, {
              default: _withCtx(() => [
                (_ctx.activePresence && _ctx.activePresence.come > 0 && _ctx.activePresence.go == 0)
                  ? (_openBlock(), _createBlock(_component_IconButton, {
                      key: 0,
                      icon: _ctx.icons.workbreak,
                      label: (_ctx.activeBreak?.stop == 0 ? _ctx.$t('button.stop_pause') : _ctx.$t('button.pause')),
                      primary: true,
                      raised: true,
                      waitForResponse: _ctx.loading.workBreak,
                      disabled: !_ctx.presenceLoaded || _ctx.readOnly,
                      onOnClick: _ctx.handlePresencePause
                    }, null, 8, ["icon", "label", "waitForResponse", "disabled", "onOnClick"]))
                  : (_openBlock(), _createBlock(_component_IconButton, {
                      key: 1,
                      icon: _ctx.icons.come,
                      label: _ctx.$t('button.come'),
                      primary: true,
                      raised: true,
                      waitForResponse: _ctx.loading.buttonCome,
                      disabled: !_ctx.presenceLoaded || _ctx.readOnly,
                      onOnClick: _ctx.createPresence
                    }, null, 8, ["icon", "label", "waitForResponse", "disabled", "onOnClick"])),
                _createVNode(_component_IconButton, {
                  icon: _ctx.icons.go,
                  label: _ctx.$t('button.go'),
                  primary: true,
                  raised: true,
                  disabled: (!_ctx.activePresence || (_ctx.activePresence && (!_ctx.activePresence?.come || _ctx.activePresence?.go != 0))) || _ctx.readOnly,
                  onOnClick: _ctx.endPresence
                }, null, 8, ["icon", "label", "disabled", "onOnClick"])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_TimeEntryList, {
          dataLoaded: _ctx.timeEntriesLoaded,
          timeEntries: _ctx.lastTimeEntries,
          activeTimeEntry: _ctx.activeTimeEntry,
          resetSwipe: _ctx.resetSwipe,
          readOnly: _ctx.readOnly,
          timeTracked: _ctx.activeTimeTracked,
          allowSwipe: true,
          onOnSwipeLeft: _ctx.onTimeEntrySwipeLeft,
          onOnSwipeRight: _ctx.onTimeEntrySwipeRight,
          onOnDelete: _ctx.deleteTimeEntry,
          onOnToggle: _ctx.onToggleTimeEntry
        }, null, 8, ["dataLoaded", "timeEntries", "activeTimeEntry", "resetSwipe", "readOnly", "timeTracked", "onOnSwipeLeft", "onOnSwipeRight", "onOnDelete", "onOnToggle"])
      ])
    ]),
    (_ctx.timeEntriesLoaded)
      ? (_openBlock(), _createBlock(_component_ActiveWorkBar, {
          key: 0,
          activeTimeTracked: _ctx.activeTimeTracked,
          currentWork: _ctx.activeTimeEntry,
          onToggleTimeEntry: _ctx.onToggleTimeEntry
        }, null, 8, ["activeTimeTracked", "currentWork", "onToggleTimeEntry"]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleOpenWorkForm && _ctx.handleOpenWorkForm(...args))),
      class: _normalizeClass(["ww-button-add", { 'disabled': _ctx.readOnly }]),
      disabled: _ctx.readOnly
    }, [
      _createElementVNode("i", {
        class: "svgicon",
        innerHTML: _ctx.store.getters.svgIcons.add
      }, null, 8, _hoisted_14)
    ], 10, _hoisted_13)
  ]))
}